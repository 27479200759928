@import url('https://fonts.googleapis.com/css?family=Nunito:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Molengo:regular,bold,italic&subset=latin,latin-ext');
/* @import "~bootstrap/scss/bootstrap"; */


.title-text {
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
  font-size: 90px;
  text-align: center;
  margin-top: 200px;
  z-index:5;
  position:relative;
  pointer-events: none;
}
.nunito {
  font-family: 'Nunito', sans-serif;
}
.smaller-title {
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
  font-size: 60px;
  margin-top: 50px;
  margin-bottom: 50px;
  z-index:5;
  position:relative;
  pointer-events: none;
}

.origami-info {
  font-size: 25px;
}
.origami-img {
  width:95%;
  height: 95%;
  border-radius: 5px;
}

.page-title {
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-size: 60px;
  margin-top: 100px;
}

.title-circles {
  margin-top: -180px;
  text-align: center;
  margin-bottom: 30px;
}

.title-circles.hovered {
  margin-top: -196px;
}

.dot {
  height: 228px;
  width: 228px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: height 0.5s, width 0.5s, opacity 0.5s;
  
}

.dot.hovered-dot {
  opacity: 1 !important;
  height: 250px !important;
  width: 250px !important;
  transition: height 0.5s, width 0.5s, opacity 0.5s;
}

.small {
  height: 150px;
  width: 150px;
}

.smallish {
  height: 100px;
  width: 100px;
  display: inline-block;
}
.xs {
  height: 30px;
  width: 30px;
  opacity: 0.2 !important;
}

.dot.hovered-dot.small {
  height: 170px !important;
  width: 170px !important;
}

.dot.hovered-dot.xs {
  height: 30px !important;
  width: 30px !important;
}

.light-g {
  background-color: #57C600;
  opacity: 0.69;
}
.g {
  background-color: #00D06C;
  opacity: 0.81;
}
.tq {
  background-color: #00D0AB;
  opacity: 0.67;
}
.db {
  background-color: #00ABD0;
  opacity: 0.67;
}

.prp {
  background-color: #0071F6;
  opacity: 0.67;
}

.d-prp {
  background-color: #855cd6;
  opacity: 0.5;
}

.highlight-lg{
  font-weight: 900 !important;
  color: #57C600 !important;
  display: inline-block;
}

.highlight-g{
  font-weight: 900;
  color: #00D06C !important;
  display: inline-block;
}
.highlight-tq{
  font-weight: 900;
  color: #00D0AB !important;
  display: inline-block;
}

.highlight-db{
  font-weight: 900;
  color: #00ABD0 !important;
  display: inline-block;
}
.highlight-prp {
  font-weight: 900;
  color: #0071F6 !important;
  display: inline-block;
}

.highlight-d-prp {
  font-weight: 900;
  color: #855cd6 !important;
}

.first {
  left: 96px;
}
.second {
  left: 32px;
}
.third {
  right: 32px;
}
.fourth {
  right: 96px;
}

.teaching-item-name {
  position: relative !important;
  margin-top: -45px !important;
  z-index: 10 !important;
}


body {
  font-family: 'Molengo', sans-serif !important;
  font-size: 35px;
  color: black !important;
  padding-left: 100px;
  padding-right: 100px;
}

.restrict-left {
  padding-right: 30%;
}


a.unlink {
  color: inherit;
  text-decoration: none;
}

a.unlink:hover {
color: inherit;
text-decoration: none;

}
a.unlink.active {
border-bottom: 4px solid #57C600;
}

.tiny-circle {
  height: 20px;
  width: 20px;
  border-radius:50%;
  display: inline-block;
  z-index: -80 !important;
}

.nav-circle {
  color: black;
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.text-on-circ {
  margin-top: 20px;
  z-index: 100;
}

.nav-item {
  text-decoration: none;
  color: black;
  margin-left: 30px;
  margin-right: 30px;

}

.nav-item:hover {
  color:black;
}


.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.circ-text {
  margin-top: -30px;
  margin-left: -50%;
}

.inline-block {
  display: inline-block !important;
}

.smaller-circ {
  position: absolute;
  height: 20px;
  width:20px;
  border-radius:50%;
  display: inline-block;
  z-index: -80 !important;
}

.small-circ {
  margin-left:-8px;
  height: 50px;
  width:50px;
  border-radius:50%;
  display: inline-block;
  margin-top:6px;
  z-index: -80 !important;
}

.med-circ {
  margin-left: -8px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: -100px;
  position: relative;
  z-index: -5;
  pointer-events: auto;
}

.med-circ:hover {
  opacity: 1;
}

.center {
  text-align: center;
}

.subtitle {
  font-size: 34px;
}

.left-title {
  font-family: "Nunito", sans-serif;
  font-size: 60px;
  margin-top: 60px;
}


.project-title {
  z-index:5;
  position:relative;
  pointer-events: none;
  font-size: 36px;
  margin-left: 60px;
  margin-top: -120px;
}

.project-built {
  z-index:5;
  position:relative;
  pointer-events: none;
  font-size: 24px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: 80px;
}
.project-link {
  z-index:5;
  position:relative;
  pointer-events: none;
  font-size: 18px;
  margin-left: 80px;
}
.grey {
  color: #6d6c6c !important;
}

.ml-3 {
  margin-left: 30px;
}
.ml-1 {
  margin-left: 10px;
}
.mr-1 {
  margin-right: 10px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 30px;
}

.no-pointer-events {
  pointer-events: none;
}

.centered {
  text-align: center;
}

.nav-item-text {
  z-index:5;
  position:relative;
  pointer-events: none;
  font-size: 18px;
  margin-top: -36px;
}

.active-nav {
  opacity: 1 !important;
}

.tiny-text {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 20px;
  

}

.contact-method {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  /* font-size: 50px; */
  color: #fff;
  text-align: center;
  background: #000
}

img {
  border-radius: 5px;
  margin-bottom: 20px;
}